import React, { useState, useEffect } from 'react';
import './index.scss';
import plusWhite from '../../assets/icons/plusWhite.svg';
import plus from '../../assets/icons/plus.svg';
import minus from '../../assets/icons/minus.svg';

interface QuantityDisplayProps {
    quantity?: number;
    borderColor?: string;
}


const QuantityDisplay: React.FC<QuantityDisplayProps> = ({
    quantity, borderColor
}) => {

    const [quantityAmount, setQuantityAmount] = useState<number>(0);


    useEffect(() => {
        const number = quantity
        if (number || number === 0) {
            setQuantityAmount(number)
        }
    }, [quantity]);

    return (
        <div className='quantityDisplay' >
            {quantityAmount === 0 ? (
                <div className='quantityDisplay-inner default' >
                    <div className='quantityDisplay-inner-icon'>
                        <img src={plusWhite} />
                    </div>
                </div>
            ) : (
                <div className='quantityDisplay-inner' style={{ borderColor: borderColor }}>
                    <div className='quantityDisplay-inner-quantity'>
                        {quantityAmount}
                    </div>
                </div>
            )}
        </div>
    );
};

export default QuantityDisplay;

import { createContext, useContext, useState, ReactNode } from 'react';

// Define the types for the notification state and its methods
interface Notification {
  message: string;
  type: 'info' | 'success' | 'error'; // You can add more types if needed
}

interface NotificationContextProps {
  notification: Notification | null;
  showNotification: (message: string, type?: 'info' | 'success' | 'error') => void;
  hideNotification: () => void;
}

// Create the context with an initial value of undefined
const NotificationContext = createContext<NotificationContextProps | undefined>(undefined);

// Define the provider component with types for the children prop
export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const [notification, setNotification] = useState<Notification | null>(null);

  const showNotification = (message: string, type: 'info' | 'success' | 'error' = 'info') => {
    setNotification({ message, type });
  };

  const hideNotification = () => {
    setNotification(null);
  };

  return (
    <NotificationContext.Provider value={{ notification, showNotification, hideNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};

// Custom hook to use the notification context
export const useNotification = () => {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }

  return context;
};

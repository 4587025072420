import React from 'react';
import './index.scss';
// Define the props interface
interface ButtonProps {
  backgroundColor?: string;
  color?: string;
  text: string;
  loading?: boolean;
  loadingText?: string;
  action: () => void;
  fontSize?: number;
  fontWeight?: string | number;
  icon?: string;
  disable?: boolean
}

// Functional component with props type
const Button: React.FC<ButtonProps> = ({
  backgroundColor = '#190DA2',
  color = '#fff',
  text,
  loading = false,
  loadingText,
  action,
  fontSize = 16,
  fontWeight = '700',
  icon,
  disable
}) => {
  return (
    <a onClick={() => { if (!loading) { action(); } }}>
      <div
        className='buttonContainer'
        style={{
          textAlign: 'center',
          backgroundColor: backgroundColor,
          color: color,
          fontSize: fontSize,
          fontWeight: fontWeight
        }} >
        {icon && (<img style={{ width: 20, height: 20, marginRight: 8 }} src={icon} />)}
        {loading ? loadingText ?? text : text}
      </div>
    </a>
  );
};

export default Button;
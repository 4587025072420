import { gql } from '@apollo/client';

// Define the query with the necessary fields
export const GET_ORDER_BY_ID = gql`
  query OrderInformation($input: OrderInformationInput!) {
    orderInformation(input: $input) {
       result {
            _id
            order_ids
            bill_group_no
            group_gross_price
            group_delivery_price
            group_net_price
            group_tax_list {
                label
                percentage
                total_price
            }
            group_order_list {
                _id
                bill_no
                bill_group_no
                store_id
                company_id
                gross_price
                total_price
                status
                payment_type
                order_type
                device
                firebase_user_id
                isSalesClosed
                table_no
                created_date
                updated_date
                products {
                    _id
                    name
                    qty
                    unit_price
                    total_price
                    options {
                        group_id
                        group_name
                        price
                        selected {
                            name
                            qty
                            unit_price
                            total_price
                        }
                    }
                    tax_list {
                        label
                        percentage
                        total_price
                    }
                }
                company {
                    _id
                    display_name
                    address
                    phone
                    email
                    description
                    store_ids
                    status
                    company_code
                    company_qr
                    main_image
                    background_image
                    show_coordinates
                    location {
                        type
                        coordinates
                    }
                }
                store {
                    _id
                    display_name
                    store_name
                    company_id
                    email
                    tel
                    website_url
                    description
                    logo_image
                    status
                    name
                    store_company_reg_no
                    last_bill_number
                    isOpen
                    whatsapp
                    xp_printer_sn
                    bank_acc_no
                    bank_id
                    login_required
                    service_tax_rate
                    government_service_tax_rate
                    created_date
                    updated_date
                    images {
                        images_uri
                        type
                    }
                    order_status {
                        label
                        value
                        next
                    }
                }
                payments {
                    _id
                    bill_id
                    bill_no
                    price
                    price_paid
                    payment_type
                    payment_ref_no
                    created_date
                }
            }
        }
    }
  }
`;
import { gql } from '@apollo/client';

export const GET_STORE_BY_ID = gql`
  query Store($id: String!) {
    store(input: { _id: $id }) {
      result {
        _id
        display_name
        store_name
        company_id
        email
        tel
        website_url
        description
        logo_image
        status
        currency_id
        name
        store_company_reg_no
        last_bill_number
        isOpen
        updated_date
        whatsapp
        xp_printer_sn
        bank_acc_no
        bank_id
        category_list {
                _id
                name
                display_name
                sort
                store_id
                status
          }
        images {
          images_uri
          type
        }
        order_status {
          label
          value
          next
        }
        product_list {
          _id
          store_id
          name
          sku_name 
          sku_number 
          description
          brand
          qty
          auto_deduct_qty
          sort
          status
          tags
          categories
          category_list {
            display_name
          }
          main_image
          price
          options
          images {
            url
          }
          option_group_list {
            _id
            store_id
            group_name
            description
            max_select
            min_select
            sort
            options {
                name
                description
                image
                price
                qty
                deduct_qty
                sort
                status
            }
        }
          created_date 
          updated_date
        }
        store_operation_hour_list {
          day
          open_time
          close_time
        }
      }
    }
  }
`;
import React, { useState } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import QuantityButton from '../QuantityButton';
import { useCart } from '../../contexts/CartContent';
import { Item } from '../../types/dataType';
import QuantityDisplay from '../QuantityDisplay';

interface ProductContainerProps {
    item: Item;
    index: number;
    addItem: (item: Item) => void;
    removeItem: (item: Item) => void;
}


const ProductContainer: React.FC<ProductContainerProps> = ({
    item, index, addItem, removeItem
}) => {
    const [error, setError] = useState(false);
    const { cart } = useCart();
    const { t } = useTranslation();

    const getItemQuantity = (itemId: string) => {
        const totalQuantity = cart.reduce((sum, cartItem) => {
            if (cartItem.item._id === itemId) {
                return sum + cartItem.quantity;
            }
            return sum;
        }, 0);

        return totalQuantity;
    };

    const displayImage = (item: any) => {
        let src = '';
        if (error) {
            // src = require('../../assets/images/logo.png');
        } else if (item?.main_image) {
            src = item?.main_image;
        } else {
            setError(true)
            // src = require('../../assets/images/logoText.png');
        }

        if (!error && item?.main_image) {
            return (
                <img
                    src={src}
                    alt="Bereach Image"
                    onError={() => setError(true)}
                />
            );
        } else {
            return null;
        }
    };



    return (
        <div key={index} className='productContainer-content-item' onClick={() => {
            if (item?.status === 1) {
                const qty = getItemQuantity(item?._id ?? '');
                if (qty == 0) {
                    addItem(item)
                } else {
                    removeItem(item)
                }
            }
        }}>
            {(item?.status === 0) && (
                <div className='unavailable'>
                </div>
            )}
            <div className='productContainer-content-item-inner'>
                <div className='productContainer-content-item-inner-left'>
                    <div className='productContainer-content-item-inner-left-name'>
                        {item?.name}
                    </div>
                    {/* <div className='productContainer-content-item-inner-left-type'>
                        {item?.category_list && item.category_list.length > 0
                            && item.category_list.map(cat => cat.display_name).filter(Boolean).join(' / ')
                        }
                    </div> */}
                    {item?.tags && (
                        <div className='productContainer-content-item-inner-left-type'>
                            {t(`${item?.tags}`)}
                        </div>
                    )}
                    <div className='productContainer-content-item-inner-left-price'>
                        {(item?.price ?? 0).toFixed(2)}
                    </div>
                    {(item?.status === 0) && (
                        <div className='productContainer-content-item-inner-left-alert'>
                            {t('Unavailable')}
                        </div>
                    )}
                </div>

                <div className={`productContainer-content-item-inner-right ${error ? ' noImage' : ''} `}>
                    <div className={`productContainer-content-item-inner-right-img ${error ? 'noImage' : ''} `}>
                        {displayImage(item)}
                    </div>
                    {(item?.status === 1) && (
                        <div className='productContainer-content-item-inner-right-icon'>
                            <QuantityDisplay quantity={getItemQuantity(item?._id ?? '')} />
                        </div>
                    )}
                </div>
            </div>

        </div>
    );
};

export default ProductContainer;

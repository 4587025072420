import React, { useEffect, useState } from 'react';
import './index.scss';
import Button from '../../components/Button';
import * as _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const ExpireQrPage = () => {

  const { t } = useTranslation();

  const navigate = useNavigate();



  return (
    <div className='cartContainer'>
      <>
        <div className='cartContainer-empty'>
          <div className='cartContainer-empty-notFound'>
            <img src={require('../../assets/images/Expired.png')} />
            <div className='cartContainer-empty-title'>
              {t('QR Code Expired')}
            </div>
            <div className='cartContainer-empty-desc'>
              {t('This QR code is no longer valid. Please inform a staff member for assistance.')}
            </div>
          </div>
        </div>
        <div className='cartContainer-cartBar'>
          <Button text={`Scan Again`} action={() => {
            navigate('/home')
          }} />
        </div>
      </>
    </div>
  );
};

export default ExpireQrPage;

import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';

interface AccountInputBoxProps {
  inputValue: string;
  label?: string;
  text?: string;
  setInputValue: (value: string) => void;
  type?: string;
}

const AccountInputBox: React.FC<AccountInputBoxProps> = ({
  inputValue,
  setInputValue,
  label,
  text,
  type = 'text'
}) => {
  const { t } = useTranslation();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(event.target.value);
  };

  return (
    <div className='accountInputBox'>
      <div className='accountInputBox-label'>{t(label ?? '')}</div>
      <input type={type} value={inputValue} onChange={handleChange} placeholder={text} />
    </div>
  );
};

export default AccountInputBox;

import React, { useEffect, useState } from 'react';
import './index.scss';
import * as _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GroupOrder } from '../../types/dataType';
import { useOrder } from '../../contexts/OrderContent';
import Header from '../../components/Header';
import orderRightIcon from '../../assets/icons/grayRight.svg';
import copyIcon from '../../assets/icons/copy.svg';
import { useNotification } from '../../contexts/NotificationContext';
import dayjs from 'dayjs';

export interface Status {
  label: string;
  description: string;
}


const OrderHistoryDetailPage = () => {
  const [orderData, setOrderData] = useState<GroupOrder>({});
  const { t } = useTranslation();
  const { getOrderDetails } = useOrder();
  const { billGroupNo } = useParams();
  const navigate = useNavigate();
  const { showNotification } = useNotification();

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (billGroupNo) {
      const fetchOrderDetails = () => {
        getOrderDetails(billGroupNo)
          .then((orderDataResult) => {
            if (orderDataResult) {
              console.log('orderDataResult', orderDataResult);
              setOrderData(orderDataResult);
            }
          })
          .catch((error) => {
            console.error('Error fetching product details:', error);
          });
      };

      fetchOrderDetails();

      intervalId = setInterval(fetchOrderDetails, 10000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [billGroupNo]);

  const status = [{
    label: 'Pending',
    value: 'pending',
    description: 'We got your order'
  }, {
    label: 'Confirmed',
    value: 'Confirmed',
    description: 'We got your order'
  }, {
    label: 'Processing',
    value: 'processing',
    description: 'Processing your order'
  }, {
    label: 'Ready',
    value: 'ready',
    description: 'Your meal is ready'
  }, {
    label: 'Completed',
    value: 'completed',
    description: 'Enjoy your meal!'
  }]


  const handleCopy = async (text: string, code: string) => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(code);
      } else {
        const textarea = document.createElement('textarea');
        textarea.value = code;
        document.body.appendChild(textarea);
        textarea.select();
        document.body.removeChild(textarea);
      }
      showNotification(text, "success");
    } catch (error) {
      console.error('Failed to copy text:', error);
    }
  };

  return (
    <div className='orderContainer'>
      <Header back title={t('Order Details')} whiteTheme backAction={() => { navigate(-1) }} />
      {!_.isEmpty(orderData) && (
        <div className='orderContainer-body'>
          <div className='orderContainer-body-orderNumberSection'>
            <div className='orderContainer-body-orderNumberSection-top'>
              <div className='orderContainer-body-orderNumberSection-label'>
                {t('Order')}
              </div>
              <div className='orderContainer-body-orderNumberSection-number'>
                #{orderData?.bill_group_no}
              </div>
            </div>
            <div className='orderContainer-body-orderNumberSection-method'>
              <div className='orderContainer-body-orderNumberSection-method-label'>
                {t(`${orderData?.group_order_list?.[0]?.order_type}`)}
              </div>
              {(orderData?.group_order_list?.[0]?.table_no && (orderData?.group_order_list?.[0]?.order_type !== 'delivery')) && (
                <div className='orderContainer-body-orderNumberSection-method-table'>
                  {orderData?.group_order_list?.[0]?.table_no}
                </div>
              )}
            </div>
          </div>
          {_.map(orderData?.group_order_list, (order, index) => (
            <div key={index} >
              <div className='orderContainer-body-statusSection'>
                <div className='orderContainer-body-statusSection-inner'>
                  {_.map(status, (item, index) => (
                    <div key={index} className='orderContainer-body-statusSection-inner-item'>
                      <div className='orderContainer-body-statusSection-inner-item-label'>
                        {t(`${item?.label}`)}
                      </div>
                      <div className={`orderContainer-body-statusSection-inner-item-status ${item?.value === order?.status ? 'active' : ''}`}>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className='orderContainer-body-deteilSection'>
                <div className='orderContainer-body-deteilSection-left'>
                  <div className='orderContainer-body-deteilSection-left-logo'>
                    <img src={order?.store?.logo_image || require('../../assets/images/logo.png')} />
                  </div>
                </div>
                <div className='orderContainer-body-deteilSection-right'>
                  <div className='orderContainer-body-deteilSection-right-label'>
                    {order?.store?.display_name ? t(`${order?.store?.display_name}`) : ''}
                  </div>
                  <div className='orderContainer-body-deteilSection-right-orderId' onClick={() => handleCopy('Bill number has been copied.', `${order?.bill_group_no}_${order?.bill_no}`)}>
                    <div className='orderContainer-body-deteilSection-right-orderId-label'>
                      {t('Bill number')}
                    </div>
                    <div className='orderContainer-body-deteilSection-right-orderId-code'>
                      {order?.bill_group_no}_{order?.bill_no}
                    </div>
                    <img src={copyIcon} />
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className='orderContainer-body-summary' onClick={() => navigate(`/orderDetail/${billGroupNo}`)}>
            <div className='orderContainer-body-summary-left'>
              {t('View order summary')}
            </div>
            <div className='orderContainer-body-summary-right'>
              <img src={orderRightIcon} />
            </div>
          </div>
          <div className='orderContainer-body-bill'>
            <div className='orderContainer-body-bill-item'>
              <div className='orderContainer-body-bill-item-left'>
                {t('Order number')}
              </div>
              <div className='orderContainer-body-bill-item-right orange' onClick={() => handleCopy('Order number has been copied.', `${billGroupNo}`)}>
                #{billGroupNo}
              </div>
            </div>
            <div className='orderContainer-body-bill-item'>
              <div className='orderContainer-body-bill-item-left'>
                {t('Date')}
              </div>
              <div className='orderContainer-body-bill-item-right'>
                {dayjs(orderData?.group_order_list?.[0]?.created_date).format('dddd, D MMM, HH:mm')}
              </div>
            </div>
            <div className='orderContainer-body-bill-item'>
              <div className='orderContainer-body-bill-item-left'>
                {t('Payment method')}
              </div>
              <div className='orderContainer-body-bill-item-right'>
                {orderData?.group_order_list?.[0]?.payments && orderData?.group_order_list?.[0]?.payments.length > 0
                  && orderData?.group_order_list?.[0]?.payments.map(cat => cat.payment_type).filter(Boolean).join(' / ')
                }
              </div>
            </div>
            <div className='orderContainer-body-bill-item'>
              <div className='orderContainer-body-bill-item-left bold'>
                {t('Order Total')}
              </div>
              <div className='orderContainer-body-bill-item-right bold'>
                RM {orderData?.group_net_price?.toFixed(2)}
              </div>
            </div>

          </div>
        </div>
      )}
    </div>
  );
};

export default OrderHistoryDetailPage;

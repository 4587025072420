import React, { createContext, useContext, useState } from 'react';

const ScrollContext = createContext<{
  position: number;
  storePosition: number;
  setPosition: (pos: number) => void;
  setStorePosition: (pos: number) => void;
} | undefined>(undefined);

export const ScrollProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [position, setPosition] = useState(0);
  const [storePosition, setStorePosition] = useState(0);

  return (
    <ScrollContext.Provider value={{ position, setPosition, setStorePosition, storePosition }}>
      {children}
    </ScrollContext.Provider>
  );
};

export const useScroll = () => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error('useScroll must be used within a ScrollProvider');
  }
  return context;
};

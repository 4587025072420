import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContent';
import { CartProvider } from './contexts/CartContent';
import { StoreProvider } from './contexts/StoreContent';
import { OrderProvider } from './contexts/OrderContent';
import { NotificationProvider } from './contexts/NotificationContext';
import { ScrollProvider } from './contexts/ScrollContext';
import { ApolloProvider } from '@apollo/client';
import createApolloClient from './lib/apollo';
// import LoginPage from './pages/';
import LandingPage from './pages/LandingPage';
import BodyContainer from './components/BodyContainer';
import HomePage from './pages/HomePage';
import OutletPage from './pages/OutletPage';
import StorePage from './pages/StorePage';
import ProductPage from './pages/ProductPage';
import CartPage from './pages/CartPage';
import CheckOutPage from './pages/CheckOutPage';
import OrderPage from './pages/OrderPage';
import Loading from './components/LoadingScreen';
import QR from './pages/QR';
import { LoadingProvider } from './contexts/LoadingContent';
import AccountPage from './pages/AccountPage';
import OrderHistoryPage from './pages/OrderHistoryPage';
import OrderSummaryPage from './pages/OrderSummaryPage';
import ExpireQrPage from './pages/ExpireQrPage';
import InvalideQrPage from './pages/InvalideQrPage';
import Notification from './components/Notification';
import EditAccountPage from './pages/EditAccountPage';
import EditLocationPage from './pages/EditLocationPage';
import AddressManagementPage from './pages/AddressManagementPage';
import OrderHistoryDetailPage from './pages/OrderHistoryDetailPage';
import NotFoundPage from './pages/NotFoundPage';

function App() {

    const client = createApolloClient();

    return (
        <ApolloProvider client={client}>
            <Router>
                <ScrollProvider>
                    <LoadingProvider>
                        <NotificationProvider>
                            <AuthProvider>
                                <CartProvider>
                                    <StoreProvider>
                                        <OrderProvider>
                                            <Loading />
                                            <Notification />
                                            <BodyContainer>
                                                <Routes>
                                                    <Route path="/home" element={<HomePage />} />
                                                    <Route path="/qr/:qrCode" element={<QR />} />
                                                    <Route path="/outlet" element={<OutletPage />} />
                                                    <Route path="/store" element={<StorePage />} />
                                                    <Route path="/editLocation" element={<EditLocationPage />} />
                                                    <Route path="/editAccount" element={<EditAccountPage />} />
                                                    <Route path="/qrExpire" element={<ExpireQrPage />} />
                                                    <Route path="/invalidQr" element={<InvalideQrPage />} />
                                                    <Route path="/orderHistory" element={<OrderHistoryPage />} />
                                                    <Route path="/account" element={<AccountPage />} />
                                                    <Route path="/addressManagement" element={<AddressManagementPage />} />
                                                    <Route path="/product/:storeId" element={<ProductPage />} />
                                                    <Route path="/orderHistoryDetail/:billGroupNo" element={<OrderHistoryDetailPage />} />
                                                    <Route path="/orderDetail/:billGroupNo" element={<OrderSummaryPage />} />
                                                    <Route path="/cart" element={<CartPage />} />
                                                    <Route path="/checkOut" element={<CheckOutPage />} />
                                                    <Route path="/order/:billGroupNo" element={<OrderPage />} />
                                                    <Route path="/" element={<LandingPage />} />
                                                    <Route path="*" element={<NotFoundPage />} />
                                                </Routes>
                                            </BodyContainer>
                                        </OrderProvider>
                                    </StoreProvider>
                                </CartProvider>
                            </AuthProvider>
                        </NotificationProvider>
                    </LoadingProvider>
                </ScrollProvider>
            </Router>
        </ApolloProvider>
    );
}

export default App;

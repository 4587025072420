import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import orderRightIcon from '../../assets/icons/grayRight.svg';
import { OrderHistory } from '../../types/dataType';

interface OrderHistoryItemProps {
  data?: OrderHistory;
}

// Functional component with props type
const OrderHistoryItem: React.FC<OrderHistoryItemProps> = ({
  data
}) => {
  const { t } = useTranslation();

  return (
    <div className='orderHistoryItem'>
      <div className='orderHistoryItem-left'>
        <div className='orderHistoryItem-left-top'>
          {t(`${data?.company_name}`)}
        </div>
        <div className='orderHistoryItem-left-middle'>
          <div className='orderHistoryItem-left-middle-data'>
            {t(`${data?.date}`)}
          </div>
          <div className='orderHistoryItem-left-middle-dot'></div>
          <div className='orderHistoryItem-left-middle-data'>
            RM {t(`${data?.total_amount?.toFixed(2)}`)}
          </div>
          {/* <div className='orderHistoryItem-left-middle-dot'></div>
          <div className='orderHistoryItem-left-middle-data' >
            {t(`${data?.qty}`)} {t(`items`)}
          </div> */}
        </div>
        {/* <div className='orderHistoryItem-left-bottom'>
          <div className={`orderHistoryItem-left-bottom-status ${data?.status === 'In Progress' && 'inProgress'} ${data?.status === 'Completed' && 'completed'}`}>
            {t(`${data?.status}`)}
          </div>
        </div> */}
      </div>
      <div className='orderHistoryItem-right'>
        <img src={orderRightIcon} />
      </div>
    </div>
  );
};

export default OrderHistoryItem;
const config = {
  VERSION_NO: '3.0.27',
  /* DEVELOPMENT ORDER SERVER */
  // GRAPHQL_HTTP: "https://api.bereach.co/staging/order/v2/graphql",
  // GRAPHQL_WS: "wss://api.bereach.co/staging/order/v1/wsql",

  /* PRODUCTION ORDER SERVER  */
  GRAPHQL_HTTP: "https://api.bereach.co/order/v2/graphql",

  MAPBOX_API_URL: 'https://api.mapbox.com/geocoding/v5/mapbox.places',
  MAPBOX_TOKEN: 'pk.eyJ1IjoiYW5ncWl4dWFuOTkiLCJhIjoiY20wbWwyejJuMDJ6MjJrcXNwNnF0YzEwYyJ9.HqcwAoTviIbMUuF9CYrJdQ',

  EXPIRE_TIME: 30
};

export default config;

import React, { useEffect, useState } from 'react';
import './index.scss';
import OutletBodyContainer from '../../components/OutletBodyContainer';
import * as _ from 'lodash';
import Header from '../../components/Header';
import ItemContainer from '../../components/ItemContainer';
import SearchStoreModal from '../../components/SearchStoreModal';
import { useNavigate } from 'react-router-dom';
import { Company, Store } from '../../types/dataType';
import OutletDetailModal from '../../components/OutletDetailModal';
import { useStore } from '../../contexts/StoreContent'
import { useScroll } from '../../contexts/ScrollContext';
import { useNotification } from '../../contexts/NotificationContext';

const StorePage = () => {
    const [modal, setModal] = useState<boolean>(false);
    const [outletModal, setOutletModal] = useState<boolean>(false);
    const [companyData, setCompanyData] = useState<Company>();
    const navigate = useNavigate();
    const { outletData, isQRExpired } = useStore()
    const { showNotification } = useNotification();
    const { storePosition, setStorePosition } = useScroll();
    const [scrollReady, setScrollReady] = useState<boolean>(false);


    useEffect(() => {
        isQRExpired();
        if (!_.isEmpty(outletData)) {
            setCompanyData(outletData?.company)
        }
    }, [outletData]);


    const goToItemPage = (item: Store) => {
        if (item?.isOpen) {
            navigate(`/product/${item?._id}`)
        } else {
            showNotification('Sorry, the shop is currently closed.', 'info')
        }
    }

    const handleScroll = () => {
        if (scrollReady === true) {
            const position = window.scrollY;
            setStorePosition(position);
        }
    };

    useEffect(() => {
        if (scrollReady === true) {
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, [scrollReady]);

    useEffect(() => {
        window.scrollTo(0, storePosition);
        setTimeout(() => {
            setScrollReady(true);
        }, 100);
    }, [companyData]);


    return (
        <div className='storeContainer'>
            <Header title={companyData?.display_name} back search scrollWithColor
                searhAction={() => setModal(true)} backAction={() => {
                    setStorePosition(0);
                    navigate('/outlet');
                }} />
            {companyData && (
                <>
                    <div className='storeContainer-background'>
                        <img src={companyData?.main_image || require('../../assets/images/outletBg.png')} />
                    </div>
                    <OutletBodyContainer data={companyData} open={() => setOutletModal(true)} >
                        <div className='storeContainer-content'>
                            {_.map(companyData?.store_list, (item, index) => (
                                <div key={index} className='storeContainer-content-item' onClick={() => goToItemPage(item)}>
                                    <ItemContainer item={item} />
                                </div>
                            ))}
                        </div>
                    </OutletBodyContainer>
                    <SearchStoreModal modal={modal} setModal={setModal} data={companyData?.store_list} />
                    {!_.isEmpty(companyData) && (
                        <OutletDetailModal modal={outletModal} setModal={setOutletModal} data={companyData} />
                    )}
                </>
            )
            }
        </div >
    );
};

export default StorePage;

import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import editAddressIcon from '../../assets/icons/editAddress.svg';
import deleteAddressIcon from '../../assets/icons/deleteAddress.svg';
import { AddressDetail } from '../../types/dataType';
import { useNavigate } from 'react-router-dom';

interface AddressItemProps {
  data?: AddressDetail;
}

// Functional component with props type
const AddressItem: React.FC<AddressItemProps> = ({
  data
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className='addressItem'>
      <div className='addressItem-left'>
        <div className='addressItem-left-top'>
          {t(`${data?.address_remark?.name}`)}
          {data?.default && (
            <div className={`addressItem-left-top-status completed`}>
              {t(`Default`)}
            </div>
          )}
        </div>
        <div className='addressItem-left-middle'>
          <div className='addressItem-left-middle-data'>
            {t(`${data?.address}`)}
          </div>
        </div>
      </div>
      <div className='addressItem-right'>
        <div onClick={() => navigate('/editLocation')}>
          <img src={editAddressIcon} />
        </div>
        <img src={deleteAddressIcon} />
      </div>
    </div>
  );
};

export default AddressItem;
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import * as _ from 'lodash';
import Header from '../../components/Header';
import OrderHistoryItem from '../../components/OrderHistoryItem';
import { AddressDetail } from '../../types/dataType';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import AddressItem from '../../components/AddressItem';

const AddressManagementPage = () => {
  const { t } = useTranslation();
  // const [orderHistory, setOrderHistory] = useState<OrderHistory[]>([]);
  const navigate = useNavigate();

  const orderHistory = [
    {
      address: 'Lot 135, Taman Sunny Haa, Jalan Tun Tun, 93250 Kuching, Sarawak.',
      lat: 1.123213,
      long: 1.234445,
      address_remark: {
        name: 'jane Smith',
        floor: '',
        street: '',
        contact_no: '',
        note: ''
      },
      default: true
    },
    {
      address: 'Lot 135, Taman Sunny Haa, Jalan Tun Tun, 93250 Kuching, Sarawak.',
      lat: 1.123213,
      long: 1.234445,
      address_remark: {
        name: 'jane Smith',
        floor: '',
        street: '',
        contact_no: '',
        note: ''
      },
      default: false
    },
    {
      address: 'Lot 135, Taman Sunny Haa, Jalan Tun Tun, 93250 Kuching, Sarawak.',
      lat: 1.123213,
      long: 1.234445,
      address_remark: {
        name: 'jane Smith',
        floor: '',
        street: '',
        contact_no: '',
        note: ''
      },
      default: false
    },
    {
      address: 'Lot 135, Taman Sunny Haa, Jalan Tun Tun, 93250 Kuching, Sarawak.',
      lat: 1.123213,
      long: 1.234445,
      address_remark: {
        name: 'jane Smith',
        floor: '',
        street: '',
        contact_no: '',
        note: ''
      },
      default: false
    }, {
      address: 'Lot 135, Taman Sunny Haa, Jalan Tun Tun, 93250 Kuching, Sarawak.',
      lat: 1.123213,
      long: 1.234445,
      address_remark: {
        name: 'jane Smith',
        floor: '',
        street: '',
        contact_no: '',
        note: ''
      },
      default: false
    },
    {
      address: 'Lot 135, Taman Sunny Haa, Jalan Tun Tun, 93250 Kuching, Sarawak.',
      lat: 1.123213,
      long: 1.234445,
      address_remark: {
        name: 'jane Smith',
        floor: '',
        street: '',
        contact_no: '',
        note: ''
      },
      default: false
    },
    {
      address: 'Lot 135, Taman Sunny Haa, Jalan Tun Tun, 93250 Kuching, Sarawak.',
      lat: 1.123213,
      long: 1.234445,
      address_remark: {
        name: 'jane Smith',
        floor: '',
        street: '',
        contact_no: '',
        note: ''
      },
      default: false
    },
  ]

  // useEffect(() => {
  //   if (data) {
  //     setOrderHistory(data);
  //   }
  // }, [data]);





  return (
    <div className='addressManagementPage'>
      <Header back title={t('Manage Address')} whiteTheme backAction={() => { navigate('/account') }} />
      <div className='addressManagementPage-content'>
        {!_.isEmpty(orderHistory) ? (
          <>
            <div className='addressManagementPage-content-header'>
              <div className='addressManagementPage-content-header-label'>
                {t('Saved Address')}
              </div>
            </div>
            <div className='addressManagementPage-content-body'>
              {_.map(orderHistory, (item, index) => (
                <div key={index} >
                  <AddressItem data={item} />
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className='addressManagementPage-content-body'>
            <div className='addressManagementPage-content-empty'>
              <div className='addressManagementPage-content-empty-notFound'>
                <img src={require('../../assets/images/locationMarker.png')} />
                <div className='addressManagementPage-content-empty-title'>
                  {t('No address saved yet')}
                </div>
                <div className='addressManagementPage-content-empty-desc'>
                  {t('Please add a new address for your better experience.')}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='addressManagementPage-footer'>
        <div className='addressManagementPage-footer-btn'>
          <Button text={`Add New Address`} action={() => {
          }} />
        </div>
      </div>
    </div>
  );
};

export default AddressManagementPage;

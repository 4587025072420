import { gql } from '@apollo/client';

export const CREATE_PRE_ORDER = gql`
  mutation CreatePreOrder($input: CreatePreOrderInput!) {
    createPreOrder(input: $input) {
      result {
        _id
        order_type
      }
    }
  }
`;
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import * as _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { UserProfile } from '../../types/dataType';
import AccountInputBox from '../../components/AccountInputBox';
import Header from '../../components/Header';
import Button from '../../components/Button';




const EditAccountPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [triggerSubmit, setTriggerSubmit] = useState(false);
  const [profileData, setProfileData] = useState<UserProfile>({});

  const data = {
    img: require('../../assets/images/logo.png'),
  }

  const onchangeData = (text: string, field: string) => {
    const inputData = _.cloneDeep(profileData);
    if (text === '') {
      _.unset(inputData, field);
    } else {
      _.set(inputData, field, text);
    }
    setProfileData(inputData);
  };



  return (
    <div className='editAccountPage'>
      <Header back title={t('Profile')} whiteTheme backAction={() => { navigate('/account') }} />
      <div className='editAccountPage-topSection'>
        <div className='editAccountPage-topSection-avatar'>
          <img src={data?.img || require('../../assets/images/logo.png')} />
        </div>
      </div>
      <div className='editAccountPage-bodySection'>
        <div className='editAccountPage-bodySection-inputBox'>
          <AccountInputBox
            label={'Name'}
            inputValue={profileData?.name ?? ''}
            text={'Name'}
            setInputValue={(e) => onchangeData(e, 'name')}
          />
        </div>
        <div className='editAccountPage-bodySection-inputBox'>
          <AccountInputBox
            label={'Email'}
            inputValue={profileData?.email ?? ''}
            text={'Email'}
            setInputValue={(e) => onchangeData(e, 'email')}
          />
        </div>
        <div className='editAccountPage-bodySection-inputBox'>
          <AccountInputBox
            label={'Mobile number'}
            inputValue={profileData?.phoneNumber ?? ''}
            text={'Mobile number'}
            setInputValue={(e) => onchangeData(e, 'phoneNumber')}
          />
        </div>
        <div className='editAccountPage-bodySection-inputBox'>
          <AccountInputBox
            type={'password'}
            label={'Password'}
            inputValue={profileData?.password ?? ''}
            text={'Password'}
            setInputValue={(e) => onchangeData(e, 'password')}
          />
        </div>
      </div>
      <div className='editAccountPage-footerSection'>
        <Button text={`Save Changes`} action={() => {
        }} />
      </div>
    </div>
  );
};

export default EditAccountPage;

import React, { useEffect } from 'react';
import { useNotification } from '../../contexts/NotificationContext';
import './index.scss'
import { useTranslation } from 'react-i18next';
import infoIcon from '../../assets/icons/infoIcon.svg';
import warning from '../../assets/icons/warning.svg';
import success from '../../assets/icons/success.svg';

const Notification = () => {
  const { notification, hideNotification } = useNotification();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        hideNotification();
      }, 2000); // Adjust the duration as needed

      return () => clearTimeout(timer);
    }
  }, [notification, hideNotification]);

  const closeNotification = () => {
    hideNotification();
  }

  if (notification) {
    return (
      <div onClick={() => closeNotification()} className={`reactDropdownAlert  ${notification ? 'show' : 'hide'}`}>
        <div className="reactDropdownAlert-inner">
          {notification?.type === 'info' &&
            (<img src={infoIcon} alt="Bereach Icon" style={{ marginRight: '8px' }} />)}
          {notification?.type === 'error' &&
            (<img src={warning} alt="Bereach Icon" style={{ marginRight: '8px' }} />)}
          {notification?.type === 'success' &&
            (<img src={success} alt="Bereach Icon" style={{ marginRight: '8px' }} />)}
          {t(`${notification?.message}`)}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Notification;
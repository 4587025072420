import React, { useEffect, useState } from 'react';
import './index.scss';
import { useParams, useNavigate } from 'react-router-dom';
import { useStore } from '../../contexts/StoreContent'
import * as _ from 'lodash';

const QR = () => {
  const { qrCode } = useParams();
  const { getQRDetails, outletData, storeQr } = useStore()
  const navigate = useNavigate();

  useEffect(() => {
    if (qrCode) {
      storeQr(qrCode);
      getQRDetails(qrCode, "null")
    }
  }, [qrCode]);

  useEffect(() => {
    if (!_.isEmpty(outletData)) {
      navigate('/outlet')
    }
  }, [outletData]);



  return null;
};

export default QR;

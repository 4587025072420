import React, { useEffect, useState } from 'react';
import './index.scss';
import Button from '../../components/Button';
import * as _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const NotFoundPage = () => {

  const { t } = useTranslation();

  const navigate = useNavigate();



  return (
    <div className='noPageFound'>
      <>
        <div className='noPageFound-empty'>
          <div className='noPageFound-empty-notFound'>
            <img src={require('../../assets/images/404.png')} />
            <div className='noPageFound-empty-title'>
              {t('404 Not Found')}
            </div>
          </div>

        </div>
        <div className='noPageFound-cartBar'>
          <Button text={`Back`} action={() => {
            navigate('/home')
          }} />
        </div>
      </>
    </div>
  );
};

export default NotFoundPage;

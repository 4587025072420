import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import * as _ from 'lodash';
import Header from '../../components/Header';
import OrderHistoryItem from '../../components/OrderHistoryItem';
import { OrderHistory } from '../../types/dataType';
import { useNavigate } from 'react-router-dom';

const OrderHistoryPage = () => {
  const { t } = useTranslation();
  const [orderHistory, setOrderHistory] = useState<OrderHistory[]>([]);
  const navigate = useNavigate();



  useEffect(() => {
    const billHistory = localStorage.getItem('bill_history_list')
    if (billHistory) {
      const billHistoryJson = JSON.parse(billHistory);
      const reversedArray = [...billHistoryJson].reverse();
      setOrderHistory(reversedArray);
    }
  }, []);





  return (
    <div className='orderHistoryPage'>
      <Header back title={t('Order History')} whiteTheme backAction={() => { navigate(-1) }} />
      <div className='orderHistoryPage-content'>
        {!_.isEmpty(orderHistory) ? (
          <>
            <div className='orderHistoryPage-content-header'>
              <div className='orderHistoryPage-content-header-label'>
                {t('All orders')}
              </div>
            </div>
            <div className='orderHistoryPage-content-body'>
              {_.map(orderHistory, (item, index) => (
                <div key={index} onClick={() => navigate(`/orderHistoryDetail/${item?.order_group_id}`)}>
                  <OrderHistoryItem data={item} />
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className='orderHistoryPage-content-body'>
            <div className='orderHistoryPage-content-empty'>
              <div className='orderHistoryPage-content-empty-notFound'>
                <img src={require('../../assets/images/order.png')} />
                <div className='orderHistoryPage-content-empty-title'>
                  {t('No orders yet')}
                </div>
                <div className='orderHistoryPage-content-empty-desc'>
                  {t('You don’t have any existing orders yet')}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderHistoryPage;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import AccountItem from '../../components/AccountItem';
import accountProfileIcon from '../../assets/icons/accountProfile.svg';
import orderIcon from '../../assets/icons/order.svg';
import logoutIcon from '../../assets/icons/logout.svg';
import addressIcon from '../../assets/icons/address.svg';
import aboutIcon from '../../assets/icons/about.svg';
import tacIcon from '../../assets/icons/tac.svg';
import ppIcon from '../../assets/icons/pp.svg';

const AccountPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const data = {
    img: require('../../assets/images/logo.png'),
    name: 'Judy',
    address: 'Kuching, Malaysia'
  }

  return (
    <div className='accountPage'>
      <div className='accountPage-topSection'>
        <div className='accountPage-topSection-avatar'>
          <img src={data?.img || require('../../assets/images/logo.png')} />
        </div>
        <div className='accountPage-topSection-info'>
          <div className='accountPage-topSection-info-name'>
            {data?.name}
          </div>
          <div className='accountPage-topSection-info-address'>
            {data?.address}
          </div>
        </div>
      </div>
      <div className='accountPage-menuSection'>
        <div onClick={() => navigate('/editAccount')}>
          <AccountItem label={'Profile'} icon={accountProfileIcon} color={'black'} />
        </div>
        <div onClick={() => navigate('/orderHistory')}>
          <AccountItem label={'Order History'} icon={orderIcon} color={'black'} />
        </div>
        <div onClick={() => navigate('/addressManagement')}>
          <AccountItem label={'Manage Address'} icon={addressIcon} color={'black'} />
        </div>
      </div>
      <div className='accountPage-menuSection'>
        <AccountItem label={'About BeReach'} icon={aboutIcon} color={'black'} />
        <AccountItem label={'Terms & Conditions'} icon={tacIcon} color={'black'} />
        <AccountItem label={'Privacy Policy'} icon={ppIcon} color={'black'} />
        <AccountItem label={'Log out'} icon={logoutIcon} color={'red'} />
      </div>
    </div>
  );
};

export default AccountPage;

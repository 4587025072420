import React, { useState, useCallback, useRef, useEffect } from 'react';
import MapGL, { Marker } from 'react-map-gl';
import config from '../../lib/config';
import { useTranslation } from 'react-i18next';
import { useLoading } from '../../contexts/LoadingContent';
import { useNotification } from '../../contexts/NotificationContext';
import { reverseGeocode } from '../../utils/mapboxUtils';
import { useCart } from '../../contexts/CartContent';
import { Address } from '../../types/dataType';
import blackBack from '../../assets/icons/blackBack.svg';
import './index.scss';
import Button from '../../components/Button';
import AccountInputBox from '../../components/AccountInputBox';
import * as _ from 'lodash';
import { useNavigate } from 'react-router-dom';

const MAPBOX_TOKEN = config?.MAPBOX_TOKEN;

type Viewport = {
  latitude: number;
  longitude: number;
  zoom: number;
  bearing?: number;
  pitch?: number;
};

const EditLocationPage = () => {
  const mapRef = useRef<any>(null);
  const { showLoading, hideLoading } = useLoading() || {};
  const { showNotification } = useNotification();
  const [selectedPlace, setSelectedPlace] = useState<Address | undefined>(undefined);
  const { selectedAddress } = useCart();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [markerPosition, setMarkerPosition] = useState<{ latitude: number; longitude: number }>({
    latitude: 1.5597515506061093,
    longitude: 110.34534195978594,
  });
  const [viewport, setViewport] = useState<Viewport>({
    latitude: 1.5597515506061093,
    longitude: 110.34534195978594,
    zoom: 20,
  });


  useEffect(() => {
    getCurrentLocation();
  }, []);

  const setLocation = async (lat: number, long: number) => {
    const result = await reverseGeocode(lat, long);
    // console.log('result', result);
    const currentLocation = {
      place_name: result,
      text: result,
      lat: lat,
      long: long
    };
    setSelectedPlace(currentLocation)
  }

  const getCurrentLocation = () => {
    showLoading?.();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setMarkerPosition({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setViewport({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            zoom: 18,
          });
          setLocation(position.coords.latitude, position.coords.longitude)
          hideLoading?.();
        },
        (error) => {
          hideLoading?.();
          if (error.code === error.PERMISSION_DENIED) {
            showNotification('Location access is denied. Please allow location access in your Phone settings.', 'error');
          } else {
            console.error('Geolocation error:', error.message);
            showNotification('Unable to retrieve location. Please ensure you have a stable network connection and GPS signal.', 'error');
          }
        }
      );
    } else {
      hideLoading?.();
      showNotification('Geolocation is not supported by this browser.', 'error');
      // console.log("Geolocation is not supported by this browser.");
    }
  };


  return (
    <div className='editLocation'>
      <MapGL
        {...viewport}
        style={{ width: '100%', height: 200 }}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        mapboxAccessToken={MAPBOX_TOKEN}
        attributionControl={false}
        logoPosition="top-right"
        ref={mapRef}
      >
        <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', top: 0, width: '100%', padding: 16 }}>
          <div style={{
            display: 'flex', alignItems: 'center', justifyContent: 'center',
            borderRadius: '100%', width: 34, height: 34, backgroundColor: 'white', marginRight: 8,
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
          }} onClick={() => navigate(-1)}>
            <img style={{ width: 16, height: 16 }} src={blackBack} />
          </div>
        </div>
        {markerPosition && (
          <Marker
            latitude={markerPosition.latitude}
            longitude={markerPosition.longitude}
          >
            <div
              style={{
                fontSize: 20,
                pointerEvents: 'none',
              }}
            >
              📍
            </div>
          </Marker>
        )}
      </MapGL>
      <div className='editLocation-modal'>
        <div className='editLocation-modal-label'>
          {t('Edit location details')}
        </div>
        <div className='editLocation-modal-body'>
          <div>
            <div className='editLocation-modal-body-label'>
              {t('Address')} <div className='editLocation-modal-body-label-require'>*</div>
            </div>
            <div className='editLocation-modal-body-currentAddress'>
              <div className='editLocation-modal-body-currentAddress-logo'>
                <img src={require('../../assets/images/marker.png')} />
              </div>
              <div className='editLocation-modal-body-currentAddress-detail'>
                {selectedAddress?.place_name}
              </div>
            </div>
          </div>
          <div style={{ marginTop: 8 }}>
            <div className='editLocation-modal-body-label'>
              {t('Address details')}
            </div>
            <AccountInputBox
              inputValue={''}
              text={'e.g. Floor, unit number'}
              setInputValue={(e) => { }} />
          </div>
          <div style={{ marginTop: 8 }}>
            <div className='editLocation-modal-body-label'>
              {t('Note to driver')}
            </div>
            <AccountInputBox
              inputValue={''}
              text={'e.g. Meet me at the lobby'}
              setInputValue={(e) => { }} />
          </div>


        </div>
        <div className='editLocation-modal-bottom'>
          <div className='editLocation-modal-bottom-btn'>
            <Button text={`Scan Again`} action={() => {
              navigate('/home')
            }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditLocationPage;

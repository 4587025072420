import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useApolloClient, ApolloClient } from '@apollo/client';
import { GET_QR } from '../query/qr_codes'
import { GET_STORE_BY_ID } from '../query/stores'
import { GET_PRODUCT_BY_ID } from '../query/products'
import { Outlet, Store, Item } from '../types/dataType';
import { useLoading } from './LoadingContent';
import { useCart } from './CartContent';
import { useNotification } from './NotificationContext';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import * as _ from 'lodash';
import config from '../lib/config'


dayjs.extend(utc);
dayjs.extend(timezone);

const MALAYSIA_TIMEZONE = 'Asia/Kuala_Lumpur';

interface StoreContextProps {
    getQRDetails: (qrCode: string | null, qrExpire: string | null) => Promise<void>;
    getStoreDetails: (storeId: string | null) => Promise<Store | undefined>;
    getProductDetails: (productId: string | null) => Promise<Item | undefined>;
    isQRExpired: () => Promise<void>;
    storeQr: (qr: string) => Promise<void>;
    outletData: Outlet;
    storeData: Store;
    productData: Item;
    error?: Boolean;
}

const StoreContext = createContext<StoreContextProps | undefined>(undefined);

export const useStore = () => {
    const context = useContext(StoreContext);
    if (!context) {
        throw new Error('useStore must be used within a StoreProvider');
    }
    return context;
}


export const StoreProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [outletData, setOutletData] = useState({})
    const [storeData, setStoreData] = useState({})
    const [productData, setProductData] = useState({})
    const apolloClient: ApolloClient<any> = useApolloClient();
    const { showLoading, hideLoading } = useLoading() || {};
    const { cleanData } = useCart();
    const navigate = useNavigate();
    const { showNotification } = useNotification();

    const isQRExpired = async () => {
        let qrCode = await localStorage.getItem('qr');
        const qrExpire = await localStorage.getItem('qr-exp-date');
        if (qrCode && qrExpire) {
            if (_.isEmpty(outletData)) {
                getQRDetails(qrCode, qrExpire)
            }
            if (qrExpire === "null") { return }
            if (dayjs().isAfter(dayjs(qrExpire))) {
                await localStorage.removeItem('qr-exp-date');
                await localStorage.removeItem('qr');
                await localStorage.removeItem('cart');
                setOutletData({});
                navigate('/qrExpire');
            }
        } else {
            await localStorage.removeItem('companyName');
            await localStorage.removeItem('companyId');
            await localStorage.removeItem('qr');
            await localStorage.removeItem('qr-exp-date');
            navigate('/home');
        }
    }

    const storeQr = async (qr: string) => {
        let qrCode = await localStorage.getItem('qr');
        if (qrCode !== qr) {
            cleanData();
            await localStorage.removeItem('cart');
        }
        localStorage.setItem('qr-exp-date', "null");
        localStorage.setItem('qr', qr);
    }

    const getQRDetails = async (qrCode: string | null, qrExpire: string | null) => {
        if (qrCode) {
            showLoading?.()
            try {
                // console.log('qrCode', qrCode);
                const result = await apolloClient.query({
                    query: GET_QR,
                    variables: { qr_code: qrCode },
                });
                const qrData = result.data?.qrCode?.result;
                // console.log('qrData', qrData);
                if (qrExpire === "null" && !_.isEmpty(qrData)) {
                    await localStorage.setItem('qr-exp-date', dayjs().add(config?.EXPIRE_TIME,
                        'minute').tz(MALAYSIA_TIMEZONE)
                        .format('YYYY-MM-DD HH:mm:ss').toString());
                }


                if (!_.isEmpty(qrData)) {
                    localStorage.setItem('companyId', qrData?.company_id);
                    localStorage.setItem('companyName', qrData?.company?.display_name);
                    setOutletData(qrData);
                } else {
                    navigate('/invalidQr');
                }
                hideLoading?.();
            } catch (err) {
                hideLoading?.();
                throw err;
            }
        }
    };

    const getStoreDetails = async (storeId: string | null): Promise<Store | undefined> => {
        if (storeId) {
            showLoading?.()
            // console.log('storeId', storeId)
            try {
                const result = await apolloClient.query({
                    query: GET_STORE_BY_ID,
                    variables: { id: storeId },
                });
                // console.log('result', result);
                const store = result.data?.store?.result;

                if (!_.isEmpty(store)) {
                    setStoreData(store);
                    // console.log('store', store);
                    hideLoading?.();
                    return store;
                } else {
                    showNotification('Something went wrong.', 'error');
                    hideLoading?.();
                    return;
                }

            } catch (err) {
                hideLoading?.();
                throw err;
            }
        }
        return undefined;
    };

    const getProductDetails = async (productId: string | null): Promise<Item | undefined> => {
        // console.log('productId', productId)
        if (productId) {
            showLoading?.()
            try {
                const result = await apolloClient.query({
                    query: GET_PRODUCT_BY_ID,
                    variables: { id: productId },
                });
                // console.log('result', result);
                const product = result.data?.product?.result;
                // console.log('store', product);
                if (!_.isEmpty(product)) {
                    setProductData(product);
                    hideLoading?.();
                    return product;
                } else {
                    showNotification('Something went wrong.', 'error');
                    hideLoading?.();
                    return;
                }
            } catch (err) {
                hideLoading?.();
                throw err;
            }
        }
        return undefined;
    };



    return (
        <StoreContext.Provider
            value={{ getQRDetails, outletData, getStoreDetails, storeData, getProductDetails, productData, isQRExpired, storeQr }}>
            {children}
        </StoreContext.Provider>
    );
};

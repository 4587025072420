import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { Store } from '../../types/dataType';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import blackClose from '../../assets/icons/blackClose.svg';
import infoBlue from '../../assets/icons/infoBlue.svg';
import locationIcon from '../../assets/icons/location.svg';
import timeIcon from '../../assets/icons/time.svg';

import ModalComponent from '../ModalComponent';



interface StoreDetailModalProps {
    modal: boolean;
    setModal: (value: boolean) => void;
    data: Store;
}


// Functional component with props type
const StoreDetailModal: React.FC<StoreDetailModalProps> = ({
    modal = false,
    setModal,
    data,
}) => {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [companyData, setCompanyData] = useState<Store>();

    useEffect(() => {
        if (data) {
            setCompanyData(data)
        }
    }, [data]);

    useEffect(() => {
        setOpenModal(modal);
    }, [modal]);

    const handleClose = () => {
        setModal(false)
        setOpenModal(false)
    };

    return (
        <>
            <ModalComponent modal={openModal} setModal={setOpenModal} fullHeight>
                <div className='outletDetailModal'>
                    <div className='outletDetailModal-modal'>
                        <div className='outletDetailModal-modal-header'>
                            <div className='outletDetailModal-modal-header-icon' onClick={() => handleClose()}>
                                <img src={blackClose} />
                            </div>
                            <div className='outletDetailModal-modal-header-text'>
                                {t(`${companyData?.display_name}`)}
                            </div>
                        </div>
                        <div className='outletDetailModal-modal-body'>
                            <div className='outletDetailModal-modal-body-inner'>
                                <div className='outletDetailModal-modal-body-inner-icon'>
                                    <img src={infoBlue} />
                                </div>
                                <div className='outletDetailModal-modal-body-inner-content'>
                                    <div className='outletDetailModal-modal-body-inner-content-label'>
                                        {t('Information')}
                                    </div>
                                    {companyData?.store_name && (
                                        <div className='outletDetailModal-modal-body-inner-content-data'>
                                            {companyData?.store_name}
                                        </div>
                                    )}
                                    {companyData?.email && (
                                        <div className='outletDetailModal-modal-body-inner-content-data'>
                                            {companyData?.email}
                                        </div>
                                    )}
                                    {companyData?.whatsapp && (
                                        <div className='outletDetailModal-modal-body-inner-content-data'>
                                            {companyData?.whatsapp}
                                        </div>
                                    )}
                                    {companyData?.tel && (
                                        <div className='outletDetailModal-modal-body-inner-content-data'>
                                            {companyData?.tel}
                                        </div>
                                    )}
                                    {companyData?.website_url && (
                                        <div className='outletDetailModal-modal-body-inner-content-data'>
                                            {companyData?.website_url}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {!_.isEmpty(companyData?.store_operation_hour_list) && (
                                <div className='outletDetailModal-modal-body-inner mt-3'>
                                    <div className='outletDetailModal-modal-body-inner-icon'>
                                        <img src={timeIcon} />
                                    </div>
                                    <div className='outletDetailModal-modal-body-inner-content'>
                                        <div className='outletDetailModal-modal-body-inner-content-label'>
                                            {t('Opening Hours')}
                                        </div>
                                        <div className='outletDetailModal-modal-body-inner-content-time'>
                                            {_.map(companyData?.store_operation_hour_list, (time, index) => (
                                                <div key={index} className='outletDetailModal-modal-body-inner-content-time-container'>
                                                    <div className='outletDetailModal-modal-body-inner-content-time-container-day'>
                                                        <div>
                                                            {t(`${time?.day}`)}
                                                        </div>
                                                    </div>
                                                    <div className='outletDetailModal-modal-body-inner-content-time-container-hour'>
                                                        <div>
                                                            {t(`${time?.open_time}`)} -  {t(`${time?.close_time}`)}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </ModalComponent>
        </>
    );
};

export default StoreDetailModal;

import React, { useEffect, useState } from 'react';
import './index.scss';
import * as _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import { GroupOrder } from '../../types/dataType';
import { useOrder } from '../../contexts/OrderContent';
import dayjs from 'dayjs';


export interface orderOption {
  group_id?: string;
  group_name?: string;
  price?: number;
  selected?: option[] | [];
}

export interface option {
  name?: string;
  qty?: number;
  total_price?: number;
  unit_price?: number;
}

const OrderSummaryPage = () => {
  const [orderData, setOrderData] = useState<GroupOrder>({});
  const { getOrderDetails } = useOrder();
  const { billGroupNo } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();


  useEffect(() => {
    if (billGroupNo) {
      getOrderDetails(billGroupNo).then((orderDataResult) => {
        if (orderDataResult) {
          console.log('orderDataResult', orderDataResult);
          setOrderData(orderDataResult);
        }
      }).catch((error) => {
        console.error('Error fetching product details:', error);
      });
    }
  }, [billGroupNo]);



  const subItemDisplay = (optionGroup: orderOption[]) => {
    return (
      <div>
        {optionGroup.map((option, optionIndex) => (
          <div key={optionIndex}>
            {(option?.selected || [])
              .filter(selectedItem => selectedItem?.qty && selectedItem?.qty > 0)
              .map((selectedItem, selectedIndex) => (
                <div className='cartContainer-cartDetail-item-inner-detail-inner-detail-sub' key={selectedIndex}>{selectedItem?.name} X
                  {selectedItem?.qty} + RM{selectedItem?.total_price}</div>
              ))}
          </div>
        ))}
      </div>
    );
  };



  return (
    <div className='orderSummaryPage'>
      <Header back title={t('Order Details')} whiteTheme backAction={() => navigate(-1)} />
      {!_.isEmpty(orderData) && (
        <>
          <div className='orderSummaryPage-header'>
            <div className='orderSummaryPage-header-order'>
              <div className='orderSummaryPage-header-top'>
                <div className='orderSummaryPage-header-top-left'>
                  {t('Order #')} {orderData?.bill_group_no}
                </div>
                <div className='orderSummaryPage-header-top-right'>
                  {/* <StatusBox status={cart?.status} /> */}
                </div>
              </div>
              <div className='orderSummaryPage-header-bottom'>
                {t('Ordered from')} {orderData?.group_order_list?.[0]?.company?.display_name} {t('on')} {dayjs(orderData?.group_order_list?.[0]?.created_date).format('D MMM YYYY')}
              </div>
            </div>
            <div className='orderSummaryPage-header-label'>
              {t('Order Summary')}
            </div>
          </div>

          <div className='orderSummaryPage-body'>
            {_.map(orderData?.group_order_list, (item, index) => (
              <div key={index}>
                <div className='orderSummaryPage-body-item'>
                  <div className='orderSummaryPage-body-item-storeName'>
                    {t(`${item?.store?.display_name}`)}
                  </div>
                  <div className='orderSummaryPage-body-item-itemList'>
                    {_.map(item?.products, (pro, index) => (
                      <div key={index} className='orderSummaryPage-body-item-itemList-item'>
                        <div className='orderSummaryPage-body-item-itemList-item-start'>
                          {pro?.qty}x
                        </div>
                        <div className='orderSummaryPage-body-item-itemList-item-center'>
                          <div className='orderSummaryPage-body-item-itemList-item-center-name'>
                            {pro?.name}
                          </div>
                          {pro?.options && (
                            <div className='orderSummaryPage-body-item-itemList-item-center-detail'>
                              {subItemDisplay(pro?.options)}
                            </div>
                          )}
                        </div>
                        <div className='orderSummaryPage-body-item-itemList-item-end'>
                          {parseFloat(pro?.total_price?.toString() ?? '0').toFixed(2)}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className='orderSummaryPage-footer'>
            <div className='orderSummaryPage-footer-label'>
              {t('Payment Detail')}
            </div>
            <div className='orderSummaryPage-footer-totalBill'>
              <div className='orderSummaryPage-footer-totalBill-item'>
                <div className='orderSummaryPage-footer-totalBill-item-left'>
                  {t('Subtotal')}
                </div>
                <div className='orderSummaryPage-footer-totalBill-item-right'>
                  RM {orderData?.group_gross_price?.toFixed(2)}
                </div>
              </div>
              {_.map(orderData?.group_tax_list, (tax, index) => (
                <div key={index} className='orderSummaryPage-footer-totalBill-item'>
                  <div className='orderSummaryPage-footer-totalBill-item-left'>
                    {t(tax?.label || '')}  ({tax?.percentage || 0}%)
                  </div>
                  <div className='orderSummaryPage-footer-totalBill-item-right'>
                    RM {tax?.total_price?.toFixed(2)}
                  </div>
                </div>
              ))}
            </div>
            <div className='orderSummaryPage-footer-cartBar'>
              <div className='orderSummaryPage-footer-cartBar-top'>
                <div className='orderSummaryPage-footer-cartBar-top-left'>
                  <div className='orderSummaryPage-footer-cartBar-top-left-label'>
                    {t('Total')}
                  </div>
                  <div className='orderSummaryPage-footer-cartBar-top-left-labelTax'>
                    {t('(incl. tax)')}
                  </div>
                </div>
                <div className='orderSummaryPage-footer-cartBar-top-right'>
                  <div className='orderSummaryPage-footer-cartBar-top-right-price'>
                    RM {orderData?.group_net_price?.toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div >
  );
};

export default OrderSummaryPage;

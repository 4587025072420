import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useApolloClient, ApolloClient } from '@apollo/client';
import { GET_ORDER_BY_ID } from '../query/getOrder';
import { Order } from '../types/dataType';
import { useLoading } from './LoadingContent';
import { useNotification } from './NotificationContext';
import * as _ from 'lodash';

interface OrderContenttProps {
    getOrderDetails: (orderId: string | null) => Promise<Order | undefined>;
}

const OrderContent = createContext<OrderContenttProps | undefined>(undefined);

export const useOrder = () => {
    const context = useContext(OrderContent);
    if (!context) {
        throw new Error('useStore must be used within a StoreProvider');
    }
    return context;
}


export const OrderProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const apolloClient: ApolloClient<any> = useApolloClient();
    const { showLoading, hideLoading } = useLoading() || {};
    const { showNotification } = useNotification();

    const getOrderDetails = async (billGroupNo: string | null): Promise<Order | undefined> => {
        if (billGroupNo) {
            showLoading?.()
            // console.log('billGroupNo', billGroupNo)
            try {
                const result = await apolloClient.query({
                    query: GET_ORDER_BY_ID,
                    variables: { input: { bill_group_no: parseInt(billGroupNo, 10) } },
                });
                // console.log('getOrderDetails', result);

                if (result?.data?.orderInformation?.result) {
                    hideLoading?.();
                    return result.data?.orderInformation?.result;
                } else {
                    showNotification('Something went wrong.', 'error');
                    hideLoading?.();
                    return;
                }
            } catch (err) {
                hideLoading?.();
                throw err;
            }
        }
        return undefined;
    };


    return (
        <OrderContent.Provider value={{ getOrderDetails }}>
            {children}
        </OrderContent.Provider>
    );
};

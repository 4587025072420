import { gql } from '@apollo/client';

// Define the query with the necessary fields
export const GET_ADS_BY_ID = gql`
  query Advertisements($input: AdvertisementsInput!) {
    advertisements(input: $input) {
      totalResult
      page
      totalPages
      result {
        _id
        store_id
        image_url
        website_url
      }
    }
  }
`;

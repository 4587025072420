import React, { useEffect, useState } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import { AdsItem } from '../../types/dataType';

interface AccountItemProps {
  ads?: AdsItem[] | [];
  adsIndex: number;
  bigger?: boolean;
}


const AdvertismentContainer: React.FC<AccountItemProps> = ({
  ads, adsIndex, bigger
}) => {
  const [displayAds, setDisplayAds] = useState<AdsItem>();


  useEffect(() => {
    if (ads && ads.length > 0) {
      setDisplayAds(ads[adsIndex]);
    }
  }, [ads, adsIndex]);



  const handleAdClick = () => {
    if (displayAds && displayAds?.website_url) {
      window.open(displayAds.website_url, '_blank');
    }
  };


  if (!_.isEmpty(displayAds))
    return (
      <div className={`adsContainer ${bigger ? 'big' : ''}`} onClick={() => handleAdClick()}>
        <img src={displayAds?.image_url ?? undefined} alt="Ad" />
      </div>
    );
};

export default AdvertismentContainer;

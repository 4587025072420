import { gql } from '@apollo/client';

export const CALCULATE_ORDER = gql`
  query CalculateOrderAmount($input: CalculateOrderAmountInput!) {
    calculateOrderAmount(input: $input) {
      net_price
      gross_price
      delivery_price
      product_tax_list {
          label
          percentage
          total_price
      }
    }
  }
`;